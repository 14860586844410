import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: 'https://hrdc.devculi.com/api/',
  timeout: 10000,
  headers: {'X-Custom-Header': 'foobar', 'X-Author' : 'Devculi'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
